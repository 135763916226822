import {
  asyncCompaniesActions,
  asyncCustomersActions,
  asyncProductFamiliesActions,
  asyncServiceOrdersActions,
  asyncTicketsActions,
  asyncTicketsCategoriesActions,
  asyncTicketsResponsesActions,
  asyncUnitsActions,
  asyncUserSacActions,
} from "app/redux/reducers";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  FormControl,
  Button,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import "./style.css";
import { colors } from "app/utils/Colors";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import SplitButton from "app/components/SplitButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { normalizeDate, renderAvatar, firstLetter } from "app/utils/appHelpers";
import { CLEAN_TICKETS_STORE } from "app/redux/modules/Tickets/constants";
import { CLEAN_TICKETS_RESPONSES_STORE } from "app/redux/modules/TicketsResponse/constants";
import { Formik, Form, FormikProps } from "formik";
import { CLEAN_CUSTOMERS_STORE } from "app/redux/modules/Users/Customers/constants";
import * as Yup from "yup";
import draftToHtml from "draftjs-to-html";
import { CLEAN_UNITS_STORE } from "app/redux/modules/Customers/Units/constants";
import SwitchTickets from "app/components/SwitchTickets";
import { WhatsApp, Mail, CloudDone } from "@mui/icons-material";
import api from "../../services/config";
import { TicketsSkeleton, FieldSkeleton, AnswerSkeleton } from "./Skeleton";

import TicketHeader from "./TicketHeader";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import createDndFileUploadPlugin from "@draft-js-plugins/drag-n-drop-upload";

const TicketDetails = () => {
  const dndFileUploadPlugin = createDndFileUploadPlugin();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [switchState, setSwitchState] = React.useState(true);
  const [image, setImage] = React.useState(null);
  const [ticket, setTicket] = React.useState(location.state?.ticket);
  const [checkContent, setCheckContent] = React.useState(false);

  const formRef = useRef(null);

  const {
    ticketsResponses,
    customers,
    sac,
    auth,
    ticketsCategories,
    companies,
    productFamilies,
    units,
  } = useSelector((state) => state);

  const icons = [
    <WhatsApp className={"h-3 w-3 self-center"} />,
    <Mail className={"h-3 w-3 self-center"} />,
    <CloudDone className={"h-3 w-3 self-center"} />,
  ];

  const throgleSwitch = (value) => {
    setSwitchState(value);
  };
  useEffect(() => {
    dispatch({ type: CLEAN_TICKETS_STORE });
    dispatch({ type: CLEAN_TICKETS_RESPONSES_STORE });
    dispatch({ type: CLEAN_CUSTOMERS_STORE });
    dispatch({ type: CLEAN_UNITS_STORE });
    dispatch({ type: CLEAN_CUSTOMERS_STORE });
  }, []);

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const fetchPromisse = async () => {
    try {
      const fetch = [
        dispatch(
          asyncUserSacActions.get("name", "desc", 1, 1, "", true, "1,4,5")
        ),
        dispatch(
          asyncTicketsCategoriesActions.get("name", "desc", 1, 1, "", true)
        ),
        dispatch(
          asyncCompaniesActions.getCompanies("name", "desc", 1, 1, "", true)
        ),
        dispatch(
          asyncProductFamiliesActions.getProductFamilies(
            "name",
            "desc",
            1,
            1,
            "",
            true
          )
        ),
      ];
      if (id) {
        fetch.push(
          dispatch(
            asyncCustomersActions.getCustomersTickets(
              "name",
              "desc",
              1,
              1,
              "",
              true,
              ticket?.responsible_user?.profile?.unit?.id
            )
          ),
          dispatch(
            asyncUnitsActions.getUnitsFromCompany(
              ticket?.responsible_user?.profile?.unit?.company?.id
            )
          ),
          dispatch(asyncTicketsResponsesActions.get(id))
        );
      }

      await Promise.all(fetch);

      return;
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (!ticket?.id && id) {
      dispatch(asyncTicketsActions.getSingle(id)).then((response) => {
        setTicket(response);
        setCheckContent(true);
      });
    }
    if (!id) {
      setCheckContent(true);
    }
    if (ticket?.id && id) {
      setCheckContent(true);
    }
  }, [id, dispatch, ticket]);

  const createNewSo = () => {
    navigate("/os/novo", { state: { ticket: ticket } });
  };

  useEffect(() => {
    if (checkContent) {
      fetchPromisse();
      setLoading(false);
    }
    console.log("console dos ticket no render 1", ticket);

    return () => {
      dispatch({ type: CLEAN_TICKETS_STORE });
      dispatch({ type: CLEAN_TICKETS_RESPONSES_STORE });
      dispatch({ type: CLEAN_CUSTOMERS_STORE });
      dispatch({ type: CLEAN_UNITS_STORE });
      dispatch({ type: CLEAN_CUSTOMERS_STORE });
    };
  }, [checkContent]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleEdit = async (values) => {
    setLoading(true);

    if (editorState.getCurrentContent().hasText()) {
      await handleSubmitAnswer();
    }
    const data = {
      title: values.title,
      ticket_category_id: values.category,
      ticket_status_id: values.status,
      user_id: values.attendant,
      unit_id: values.unit,
      responsible_user_id: values.client,
      product_family_id: values.family,
    };
    dispatch(asyncTicketsActions.edit(data, ticket.id)).then(() =>
      navigate(`/tickets`)
    );
  };

  const handleCreate = (values) => {
    setLoading(true);

    const data = {
      title: values.title,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      ticket_channel_id: 3,
      ticket_category_id: values.category,
      ticket_status_id: values.status,
      responsible_user_id: values.client,
      unit_id: values.unit,
      user_id: values.attendant,
      ticket_response_is_public: switchState,
      product_family_id: values.family,
    };
    dispatch(asyncTicketsActions.create(data)).then(() => navigate(`/tickets`));
  };

  const isHtml = (str) => {
    const htmlRegex = /(<([^>]+)>)/gi;
    return htmlRegex.test(str);
  };

  const validationSchema = Yup.object().shape({
    client: Yup.string().required("Obrigatório"),
    title: Yup.string().required("Obrigatório"),
    family: Yup.string().required("Obrigatório"),
    category: Yup.string().required("Obrigatório"),
    attendant: Yup.string().required("Obrigatório"),
    company: Yup.string().required("Obrigatório"),
    unit: Yup.string().required("Obrigatório"),
  });

  const handleSubmitAnswer = async () => {
    const dataAnswer = {
      response: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      ticket_id: id,
      is_public: switchState,
    };

    dispatch(asyncTicketsResponsesActions.post(dataAnswer));
  };

  const insertFileLinkIntoEditor = (fileUrl, fileName) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url: fileUrl }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    const textWithEntity = Modifier.insertText(
      newEditorState.getCurrentContent(),
      newEditorState.getSelection(),
      fileName,
      null,
      entityKey
    );
    setEditorState(
      EditorState.push(newEditorState, textWithEntity, "insert-characters")
    );
  };

  const uploadCallback = (file, callback) => {
    return new Promise((resolve, reject) => {
      const fileImage = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      const formData = new FormData();

      formData.append(`files`, fileImage);

      api
        .post("/files-upload", formData, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          const fileName = data[0].name;
          const fileExtension = fileName.split(".").pop().toLowerCase();
          const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
          if (!imageExtensions.includes(fileExtension)) {
            insertFileLinkIntoEditor(
              `https://storage.googleapis.com/krebs-os-bucket/${data[0].name}`,
              `https://storage.googleapis.com/krebs-os-bucket/${data[0].name}`
            );
          }
          resolve({
            data: {
              link: `https://storage.googleapis.com/krebs-os-bucket/${data[0].name}`,
            },
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const renderResponse = (item) => {
    if (isHtml(item.response)) {
      return (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: item.response,
            }}
          />
          {item.attachments.length > 0 && (
            <>
              <div>
                <p>Anexos:</p>
                <ul>
                  {item.attachments.map((attachment) => (
                    <li className="py-2">
                      <div>
                        <SimCardDownloadIcon
                          style={{ fontSize: 25, cursor: "pointer" }}
                          onClick={() => {
                            window.open(
                              `${process.env.REACT_APP_BUCKET_PATH}ticket-responses/${attachment.name}`
                            );
                          }}
                        />
                        <a
                          target="_blank"
                          href={`${process.env.REACT_APP_BUCKET_PATH}ticket-responses/${attachment.name}`}
                        >
                          {attachment.name}
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </>
      );
    }
    if (item.response && !item?.whatsapp_message?.media_content_type) {
      return <div>{item.response}</div>;
    }
    if (item.whatsapp_message.message_type === "document") {
      return (
        <>
          {item.response && <div>{item.response}</div>}
          <div>
            <SimCardDownloadIcon
              style={{ fontSize: 50, cursor: "pointer" }}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BUCKET_PATH}${item.whatsapp_message.media_name}`
                );
              }}
            />
            Baixar arquivo
          </div>
        </>
      );
    }
    if (item.whatsapp_message.media_content_type === "image/jpeg") {
      return (
        <>
          {item.response && <div>{item.response}</div>}

          <img
            src={`${process.env.REACT_APP_BUCKET_PATH}${item.whatsapp_message.media_name}`}
            alt="Imagem"
            style={{ width: "fit-content" }}
          />
        </>
      );
    }
    if (item.whatsapp_message.media_content_type === "audio/ogg") {
      return (
        <>
          {item.response && <div>{item.response}</div>}

          <audio controls>
            <source
              src={`${process.env.REACT_APP_BUCKET_PATH}${item.whatsapp_message.media_name}`}
              type="audio/ogg"
            />
          </audio>
        </>
      );
    }
    if (item.whatsapp_message.media_content_type === "video/mp4") {
      return (
        <>
          {item.response && <div>{item.response}</div>}
          <video controls style={{ maxWidth: "250px" }}>
            <source
              src={`${process.env.REACT_APP_BUCKET_PATH}${item.whatsapp_message.media_name}`}
              type="video/mp4"
            />
          </video>
        </>
      );
    }
  };
  return (
    <>
      {loading ? (
        <TicketsSkeleton />
      ) : (
        <>
          <TicketHeader ticket={ticket} />

          <Formik
            initialValues={{
              title: ticket?.title || "",
              description: ticket?.description || "",
              category: ticket?.category?.id || "",
              status: ticket?.status?.id ?? "",
              attendant: ticket?.attendant?.id || "",
              client: ticket?.responsible_user?.id || "",
              company:
                ticket?.responsible_user?.profile?.unit?.company?.id || "",
              unit: ticket?.responsible_user?.profile.unit_id || "",
              cc: ticket?.users_cc?.map((item) => item.id) || [],
              family: ticket?.product_family_id || "",
            }}
            innerRef={formRef}
            onSubmit={(values) => {
              ticket?.title ? handleEdit(values) : handleCreate(values);
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              setFieldValue,
              resetForm,
            }) => (
              <Form style={$form}>
                <Div style={$container}>
                  {asyncTicketsActions.loading && <div>Carregando...</div>}
                  <Stack style={$leftBlock}>
                    <Div style={$leftBlockRequestInfo}>
                      {!id && (
                        <FormControl
                          sx={{ width: "100%", marginBottom: "20px" }}
                        >
                          <TextField
                            fullWidth
                            sx={{
                              zIndex: 0,
                              backgroundColor: colors.standardWhite,
                              borderColor: colors.borderColors,
                            }}
                            id={"title"}
                            name={"title"}
                            label={"Título do Ticket"}
                            InputProps={{
                              autoComplete: "off",
                            }}
                            onChange={handleChange}
                            error={touched.title && Boolean(errors.title)}
                            helperText={touched.title && errors.title}
                          ></TextField>
                        </FormControl>
                      )}
                      {sac.loading ? (
                        <FieldSkeleton />
                      ) : (
                        <FormControl
                          sx={{ width: "100%", backgroundColor: "#ffffff" }}
                        >
                          <Autocomplete
                            filterSelectedOptions
                            options={sac.data.data}
                            fullWidth
                            InputProps={{
                              autoComplete: "off",
                            }}
                            onChange={(event, value) =>
                              setFieldValue(
                                "attendant",
                                value !== null ? value.id : ""
                              )
                            }
                            defaultValue={sac?.data?.data?.find(
                              (option) => option.id === values.attendant
                            )}
                            getOptionLabel={(option) => option.profile.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                id={"attendant"}
                                name={"attendent"}
                                label={"Responsável pelo Atendimento"}
                                error={
                                  touched.attendant && Boolean(errors.attendant)
                                }
                                helperText={
                                  touched.attendant && errors.attendant
                                }
                              />
                            )}
                          />
                        </FormControl>
                      )}
                      {ticketsCategories.loading ? (
                        <FieldSkeleton />
                      ) : (
                        <FormControl
                          sx={{
                            width: "100%",
                            backgroundColor: "#ffffff",
                            marginTop: "20px",
                          }}
                        >
                          <Autocomplete
                            options={ticketsCategories.data.data}
                            getOptionLabel={(option) => option?.name}
                            filterSelectedOptions
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setFieldValue("category", newValue.id);
                              } else {
                                setFieldValue("category", "");
                              }
                            }}
                            defaultValue={ticketsCategories?.data?.data?.find(
                              (option) => option.id === values.category
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                id="category"
                                name="category"
                                label={"Categoria do Ticket"}
                                value={values.category}
                                error={
                                  touched.category && Boolean(errors.category)
                                }
                                helperText={touched.category && errors.category}
                              />
                            )}
                          />
                        </FormControl>
                      )}
                      {companies.loading ? (
                        <FieldSkeleton />
                      ) : (
                        <FormControl
                          sx={{
                            width: "100%",
                            backgroundColor: "#ffffff",
                            marginTop: "20px",
                          }}
                        >
                          <Autocomplete
                            options={companies.data.data}
                            getOptionLabel={(option) => option?.name}
                            filterSelectedOptions
                            InputProps={{
                              autoComplete: "off",
                            }}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setFieldValue("company", newValue.id);
                                dispatch(
                                  asyncUnitsActions.getUnitsFromCompany(
                                    newValue.id
                                  )
                                );
                              } else {
                                setTimeout(() => {
                                  setFieldValue("company", "");
                                  setFieldValue("unit", "");
                                  setFieldValue("client", "");
                                }, 0);
                              }
                              dispatch({ type: CLEAN_CUSTOMERS_STORE });
                            }}
                            defaultValue={companies?.data?.data.find(
                              (option) => option.id === values.company
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                id="company"
                                name="company"
                                label={"Empresa"}
                                value={values.company}
                                error={
                                  touched.company && Boolean(errors.company)
                                }
                                helperText={touched.company && errors.company}
                              />
                            )}
                          />
                        </FormControl>
                      )}
                      {units.loading ? (
                        <FieldSkeleton />
                      ) : (
                        <FormControl
                          sx={{
                            width: "100%",
                            backgroundColor: "#ffffff",
                            marginTop: "20px",
                          }}
                        >
                          <TextField
                            fullWidth
                            select
                            label={"Unidade"}
                            id={"unit"}
                            name={"unit"}
                            disabled={values.company === "" || units.loading}
                            value={values.unit}
                            error={touched.unit && Boolean(errors.unit)}
                            helperText={touched.unit && errors.unit}
                            onChange={(event) => {
                              setFieldValue(
                                "unit",
                                event.target.value !== null
                                  ? event.target.value
                                  : values.unit
                              );
                              dispatch(
                                asyncCustomersActions.getCustomersTickets(
                                  "name",
                                  "desc",
                                  1,
                                  1,
                                  "",
                                  true,
                                  event.target.value
                                )
                              );
                            }}
                          >
                            {units?.data?.data?.map((unit) => (
                              <MenuItem value={unit.id}>
                                {unit.address}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      )}

                      {customers.loading ? (
                        <FieldSkeleton />
                      ) : (
                        <FormControl
                          sx={{
                            width: "100%",
                            backgroundColor: "#ffffff",
                            marginTop: "20px",
                          }}
                        >
                          <TextField
                            fullWidth
                            select
                            label={"Solicitante"}
                            id={"client"}
                            name={"client"}
                            disabled={
                              units?.data?.length === 0 ||
                              customers?.data?.length === 0 ||
                              customers.loading
                            }
                            value={values.client}
                            error={touched.client && Boolean(errors.client)}
                            helperText={touched.client && errors.client}
                            onChange={(event) => {
                              setFieldValue(
                                "client",
                                event.target.value !== null
                                  ? event.target.value
                                  : values.client
                              );
                            }}
                          >
                            {customers?.data?.data?.map((client) => (
                              <MenuItem value={client.id}>
                                {client.profile.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      )}
                      {productFamilies.loading ? (
                        <FieldSkeleton />
                      ) : (
                        <FormControl
                          sx={{
                            width: "100%",
                            backgroundColor: "#ffffff",
                            marginTop: "20px",
                          }}
                        >
                          <Autocomplete
                            options={productFamilies.data.data}
                            getOptionLabel={(option) => option?.name}
                            filterSelectedOptions
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setFieldValue("family", newValue.id);
                              } else {
                                setFieldValue("family", "");
                              }
                            }}
                            defaultValue={productFamilies.data.data?.find(
                              (option) => option.id === values.family
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                id="family"
                                name="family"
                                label={"Família de Produtos"}
                                value={values.family}
                                error={touched.family && Boolean(errors.family)}
                                helperText={touched.family && errors.family}
                              />
                            )}
                          />
                        </FormControl>
                      )}
                    </Div>
                  </Stack>
                  <Stack className={"midBlock"} style={$midBlock}>
                    {ticket?.title && (
                      <Div style={$titleTicketBlock}>
                        {ticket?.responsible_user?.profile?.avatar ? (
                          <Div>
                            {renderAvatar(
                              ticket?.responsible_user?.profile?.avatar
                            )}
                          </Div>
                        ) : (
                          <Div>
                            {firstLetter(
                              ticket?.responsible_user?.profile?.name
                            )}
                          </Div>
                        )}

                        <Div>
                          <Div style={$ticektTitle}>{ticket.title}</Div>

                          <Div>
                            {normalizeDate(ticket.created_at)} -{" "}
                            {ticket.responsible_user?.profile?.name} -{" "}
                            {ticket.responsible_user?.email}
                          </Div>
                        </Div>
                      </Div>
                    )}
                    <Div
                      style={
                        switchState
                          ? {
                              ...$ticketResponseFieldBlockWrapper,
                              ...$switchOn,
                            }
                          : {
                              ...$ticketResponseFieldBlockWrapper,
                              ...$switchOff,
                            }
                      }
                    >
                      <Div style={$ticketResponseFieldBlock}>
                        {auth.user.profile.avatar ? (
                          <Div>{renderAvatar(auth.user.profile.avatar)}</Div>
                        ) : (
                          <Div>{firstLetter(auth.user.profile.name)}</Div>
                        )}

                        <FormControl sx={{ width: "100%" }}>
                          <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={onEditorStateChange}
                            plugins={[dndFileUploadPlugin]}
                            toolbar={{
                              image: {
                                uploadCallback: uploadCallback,
                                previewImage: false,
                                alt: { present: true, mandatory: false },
                                inputAccept:
                                  "image/gif,image/jpeg,image/jpg,image/png,image/svg,application/pdf,video/mp4,audio/ogg",
                              },
                            }}
                          />
                        </FormControl>
                      </Div>
                      <Div className="pl-[73px]">
                        <SwitchTickets
                          switchState={switchState}
                          setSwitchState={throgleSwitch}
                        />
                      </Div>
                    </Div>

                    {ticketsResponses.loading ? (
                      <AnswerSkeleton />
                    ) : (
                      <Div style={$answerBlock}>
                        {ticketsResponses?.data?.data?.map((item, index) => (
                          <Div
                            style={{
                              ...$answer,
                              ...(item?.is_public ? $switchOn : $switchOff),
                            }}
                            key={index}
                          >
                            {item?.user?.profile?.avatar ? (
                              <Div
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(
                                    `/usuarios/${item.user_id}/detalhes`
                                  );
                                }}
                              >
                                {renderAvatar(item?.user?.profile?.avatar)}
                              </Div>
                            ) : (
                              <Div
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(
                                    `/usuarios/${item.user_id}/detalhes`
                                  );
                                }}
                              >
                                {firstLetter(item?.user?.profile?.name)}
                              </Div>
                            )}

                            <Div style={$answerRenderBlock}>
                              <Div style={$answerUserTitle}>
                                <span
                                  style={{
                                    ...$userName,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate(
                                      `/usuarios/${item.user_id}/detalhes`
                                    );
                                  }}
                                >
                                  {item?.user?.profile?.name}
                                  {" - "}
                                  {icons[item?.ticket_channel_id - 1]}
                                  {item?.is_public === 0 &&
                                    " - Comentário interno"}
                                </span>
                                <span style={$userName}>
                                  {normalizeDate(item?.created_at)}
                                </span>
                              </Div>
                              {renderResponse(item)}
                            </Div>
                          </Div>
                        ))}
                      </Div>
                    )}
                  </Stack>
                </Div>
                <Div
                  style={{
                    ...$footerBlock,
                    justifyContent: ticket?.id ? "space-between" : "flex-end",
                  }}
                >
                  {ticket?.id && (
                    <Button
                      variant="outlined"
                      data-cy="openModal"
                      sx={{
                        justifyContent: "center",
                        color: "black",
                        backgroundColor: "white",
                        borderColor: "black",
                        borderWidth: "1px",
                        borderStyle: "solid",
                      }}
                      onClick={() => createNewSo()}
                    >
                      Nova Ordem de Serviço
                    </Button>
                  )}

                  <SplitButton
                    loading={loading}
                    setFieldValue={setFieldValue}
                  />
                </Div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};
const $form = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};
const $leftBlockRequestInfo = {
  padding: "40px 20px",
  borderBottom: "solid 1px",
  borderColor: "#ccc",
};
const $answerRenderBlock = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: 10,
};
const $userName = {
  fontSize: 14,
  fontWeight: 500,
};
const $answerUserTitle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};
const $answer = {
  padding: 20,
  borderTop: "solid 1px",
  borderColor: colors.borderColors,
  flexDirection: "row",
  display: "flex",
};

const $answerBlock = {
  borderTop: "solid 1px",
  borderColor: colors.borderColors,
};

const $ticketResponseFieldBlock = {
  padding: 20,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  transition: "background-color 0.4s ease",
};
const $ticketResponseFieldBlockWrapper = {
  transition: "background-color 0.4s ease",
};

const $switchOn = {
  backgroundColor: "#fff",
};

const $switchOff = {
  backgroundColor: "#fff6f6",
};

const $titleTicketBlock = {
  display: "flex",
  flexDirection: "row",
  padding: 20,
  borderBottom: "solid 1px",
  borderColor: colors.borderColors,
};
const $ticektTitle = {
  fontSize: 20,
  fontWeight: 500,
};
const $headerBlock = {
  backgroundColor: colors.standardWhite,
  maxWidth: "100%",
  padding: 10,
  flexDirection: "row",
  display: "flex",
  borderTop: "solid 1px",
  borderBottom: "solid 1px",
  borderColor: colors.borderColors,
};
const $footerBlock = {
  backgroundColor: colors.standardWhite,
  maxWidth: "100%",
  padding: 10,
  flexDirection: "row",
  display: "flex",
  borderTop: "solid 1px",
  borderBottom: "solid 1px",
  borderColor: colors.borderColors,
  minHeight: "6vh",
  flexWrap: "wrap",
  alignContent: "center",
};

const $midBlock = {
  backgroundColor: "#fff",
  flex: 1,
  overflow: "scroll",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "red",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  maxHeight: "77vh",
};

const $tagsTop = {
  border: "solid 1px ",
  borderColor: colors.borderColors,
  padding: "6px 12px",
  backgroundColor: colors.standardGray,
  display: "flex",
  alignContent: "center",
  flexWrap: "wrap",
  cursor: "pointer",
};
const $tagsTopLeft = {
  borderTopLeftRadius: "5px",
  borderBottomLeftRadius: "5px",
  borderRight: 0,
};
const $tagsTopRight = {
  borderTopRightRadius: "5px",
  borderBottomRightRadius: "5px",
  borderLeft: 0,
  backgroundColor: "#fff",
};

const $leftBlock = {
  backgroundColor: colors.standardGray,
  flex: 1,
  maxWidth: "25%",
};

const $container = {
  flex: 1,
  display: "flex",
  padding: 0,
};

export default TicketDetails;
